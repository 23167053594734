import { useForm } from '@mantine/form';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomLoader } from '../../components/CustomLoader';
import { Page } from '../../components/Page';
import {
  useGetActivityLayoutFile,
  useGetActivityLayoutToApprove,
  useVendorApproveActivityLayout,
  useRequestClientApprovalActivityLayout,
  useAddActivityLayoutFile,
  useClientApproveActivityLayout,
} from '../../data/hooks/activity-layouts';
import { uploadMultipleFilesRequest } from '../../data/services/files';
import { UserRole } from '../../models/user';
import { RootState } from '../../providers/store';

import { ActivityLayoutsToApproveResponse } from '../../utils/types/data/services/activity-layouts';
import { CompanyLayoutList } from './company';
import { VendorLayoutList } from './vendor';

export type ActivityLayoutApproveFormType = {
  isApproved: boolean;
  vendorNote?: string;
  clientNote?: string;
};

export type FilterLayoutsFormType = {
  os?: string;
  company?: string;
  status?: string;
};

export function LayoutList() {
  const [pageModal, setPageModal] = useState(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const [selectedLayout, setSelectedLayout] =
    useState<ActivityLayoutsToApproveResponse>();
  const page = useRef(1);
  const limit = useRef(10);
  const {
    fetch: getAtivityLayoutToApproveFetcher,
    reponseData: getAtivityLayoutToApproveData,
    loading: getAtivityLayoutToApproveLoader,
  } = useGetActivityLayoutToApprove();
  const {
    fetch: getActivityLayoutFilesFetcher,
    loading: getActivityLayoutFilesLoader,
    reponseData: getActivityLayoutFilesData,
  } = useGetActivityLayoutFile();
  const {
    fetch: vendorApproveLayoutFetcher,
    loading: vendorApproveLayoutLoader,
  } = useVendorApproveActivityLayout();
  const {
    fetch: clientApproveLayoutFetcher,
    loading: clientApproveLayoutLoader,
  } = useClientApproveActivityLayout();
  const {
    fetch: requestClientApprovalFetcher,
    loading: requestClientApprovalLoader,
  } = useRequestClientApprovalActivityLayout();
  const { fetch: addActivityFileFetcher, loading: addActivityFileLoader } =
    useAddActivityLayoutFile();

  const approveActivityForm = useForm<ActivityLayoutApproveFormType>({
    initialValues: {
      isApproved: false,
      vendorNote: '',
      clientNote: '',
    },
  });

  const formLayoutFile = useForm({
    initialValues: {
      files: [],
    },
  });

  const filterLayoutForm = useForm<FilterLayoutsFormType>({
    initialValues: {
      os: '',
      company: '',
      status: '',
    },
  });

  function handlePaginate(newPage: number) {
    page.current = newPage;
    getLayouts();
  }

  function handleFilter() {
    page.current = 1;
    limit.current = 10;
    getLayouts();
  }

  function handleClear() {
    page.current = 1;
    limit.current = 10;
    filterLayoutForm.reset();
    getLayouts();
  }

  function handleSelectLayout(item: ActivityLayoutsToApproveResponse) {
    setSelectedLayout(item);
    getLastLayoutFiles(item.id);
    setPageModal(true);
  }

  async function getLayouts() {
    const validatedFilter: any = {};

    Object.entries(filterLayoutForm.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    await getAtivityLayoutToApproveFetcher({
      query: {
        page: page.current,
        limit: limit.current,
        ...validatedFilter,
      },
    });
  }

  async function getLastLayoutFiles(id: number) {
    await getActivityLayoutFilesFetcher({ query: { activityLayout: id } });
  }

  async function handleVendorApproveLayout(
    values: typeof approveActivityForm.values,
  ) {
    if (selectedLayout) {
      if (formLayoutFile.values.files.length && !values.isApproved) {
        handleAddLayoutFile(formLayoutFile.values);
      }

      await vendorApproveLayoutFetcher({
        id: selectedLayout?.id,
        data: {
          ...values,
        },
        onSuccess: () => {
          setPageModal(false);
          getLayouts();
          approveActivityForm.reset();
          setSelectedLayout(undefined);
        },
      });
    }
  }

  async function handleCompanyApproveLayout(
    values: typeof approveActivityForm.values,
  ) {
    if (selectedLayout) {
      if (formLayoutFile.values.files.length && !values.isApproved) {
        await handleAddLayoutFile(formLayoutFile.values);
      }

      await clientApproveLayoutFetcher({
        id: selectedLayout?.id,
        data: {
          ...values,
        },
        onSuccess: () => {
          setPageModal(false);
          getLayouts();
          approveActivityForm.reset();
          setSelectedLayout(undefined);
        },
      });
    }
  }

  async function handleRequestClientApproval() {
    if (selectedLayout) {
      await requestClientApprovalFetcher({
        id: selectedLayout.id,
        onSuccess: () => {
          setPageModal(false);
          getLayouts();
          setSelectedLayout(undefined);
        },
      });
    }
  }

  async function handleAddLayoutFile(values: typeof formLayoutFile.values) {
    if (selectedLayout && values.files.length) {
      const fileIds = await uploadMultipleFilesRequest({
        files: values.files,
      });

      await addActivityFileFetcher({
        id: selectedLayout.id,
        data: {
          files: fileIds,
        },
      });
    }
  }

  useEffect(() => {
    getLayouts();
  }, []);

  return (
    <Page title="Layouts">
      <CustomLoader
        loading={
          getActivityLayoutFilesLoader ||
          getAtivityLayoutToApproveLoader ||
          vendorApproveLayoutLoader ||
          requestClientApprovalLoader ||
          addActivityFileLoader ||
          clientApproveLayoutLoader
        }
      />
      {user?.role === UserRole.VENDOR && (
        <VendorLayoutList
          approveActivityForm={approveActivityForm}
          getActivityLayoutFilesData={getActivityLayoutFilesData}
          getAtivityLayoutToApproveData={getAtivityLayoutToApproveData}
          handleApproveLayout={handleVendorApproveLayout}
          handlePaginate={handlePaginate}
          handleRequestClientApproval={handleRequestClientApproval}
          handleSelectLayout={handleSelectLayout}
          page={page}
          selectedLayout={selectedLayout}
          setSelectedLayout={setSelectedLayout}
          pageModal={pageModal}
          setPageModal={setPageModal}
          handleAddLayoutFile={handleAddLayoutFile}
          filterLayoutForm={filterLayoutForm}
          formLayoutFile={formLayoutFile}
          handleFilter={handleFilter}
          handleClear={handleClear}
        />
      )}
      {user?.role === UserRole.COMPANY && (
        <CompanyLayoutList
          approveActivityForm={approveActivityForm}
          getActivityLayoutFilesData={getActivityLayoutFilesData}
          getAtivityLayoutToApproveData={getAtivityLayoutToApproveData}
          handleApproveLayout={handleCompanyApproveLayout}
          handlePaginate={handlePaginate}
          handleSelectLayout={handleSelectLayout}
          page={page}
          selectedLayout={selectedLayout}
          pageModal={pageModal}
          setPageModal={setPageModal}
          formLayoutFile={formLayoutFile}
          handleAddLayoutFile={handleAddLayoutFile}
          filterLayoutForm={filterLayoutForm}
          handleFilter={handleFilter}
          handleClear={handleClear}
        />
      )}
    </Page>
  );
}
