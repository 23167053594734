import {
  Alert,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Group,
  Modal,
  Paper,
  ScrollArea,
  Select,
  Text,
  TextInput,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconArrowLeft,
  IconClock,
  IconInfoCircle,
  IconPlayerPlay,
  IconPlayerStop,
} from '@tabler/icons';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CustomLoader } from '../../components/CustomLoader';
import { rootNavigateGoBack } from '../../components/CustomRouter';
import { OrderDetails } from '../../components/OrderDetails';

import { Page } from '../../components/Page';
import { useListOptions } from '../../data/hooks/options';
import {
  useIsOrderProductionPartialFinished,
  useSetClockOutOrderProduction,
  useSetOrderProductionPartialFinish,
} from '../../data/hooks/order-production';
import { useSetOrderProductionFinished } from '../../data/hooks/orders';
import { getFilterUsersListRequest } from '../../data/services/filters';
import {
  getLastOrderProductionRequest,
  getOrderProductionRequest,
  setOrderProductionRequest,
  setOutOrderProductionRequest,
} from '../../data/services/order-production';
import {
  getOrderDetailsRequest,
  getOrderRequest,
} from '../../data/services/orders';
import { useTimer } from '../../hooks/use-timer';
import { OptionSubTypes, OptionTypes } from '../../models/option';
import { Order, OrderStatus } from '../../models/order';
import { OrderDetail } from '../../models/order-detail';
import {
  OrderProduction as OrderProductionModel,
  ProductionType,
} from '../../models/order-production';
import { TeamConfigAlias } from '../../models/team-config';
import { UserRole } from '../../models/user';
import { formatLocale } from '../../providers/dayjs-plugins';
import { errorNotification } from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import {
  orderProductionTypeHumanized,
  orderStatusColor,
  orderStatusHumanized,
} from '../../utils/constants';
import { secondsToTime } from '../../utils/helpers';
import { GetFilterUsersListResponse } from '../../utils/types/data/services/filters';
import { useGetActivityLayoutByOrder } from '../../data/hooks/activity-layouts';
import { ActivityLayoutItem } from '../../components/ActivityLayout';

type PageModalState =
  | 'begin-production'
  | 'end-production'
  | 'finish-all'
  | null;
export function OrderProduction() {
  const { id } = useParams();
  const { user, userTeam } = useSelector((state: RootState) => state.auth);
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [finishAsPartial, setFinishAsPartial] = useState(false);
  const [pageModalVisible, setPageModalVisible] =
    useState<PageModalState>(null);
  const [orderProductionLoading, setOrderProductionLoading] = useState(false);
  const [order, setOrder] = useState<Order>();
  const [orderDetails, setOrderDetails] = useState<OrderDetail>();
  const [orderProductionList, setOrderProductionList] = useState<
    OrderProductionModel[]
  >([]);
  const [lastProduction, setLastProduction] = useState<OrderProductionModel>();
  const [isOnProgress, setisOnProgress] = useState(false);
  const [technicalUsers, setTechnicalUsers] = useState<
    GetFilterUsersListResponse[]
  >([]);

  const {
    fetch: listMachineOptionsFetcher,
    reponseData: listMachineOptionsData,
    loading: listMachineOptionsLoader,
  } = useListOptions();

  const { fetch: setClockOutFetcher, loading: setClockOutLoader } =
    useSetClockOutOrderProduction();

  const { fetch: setPartialFinishFetcher, loading: setPartialFinishLoader } =
    useSetOrderProductionPartialFinish();

  const {
    fetch: setOrderProductionFinishedFetcher,
    loading: setOrderProductionFinishedLoader,
  } = useSetOrderProductionFinished();

  const {
    fetch: isPartialFinishedFetcher,
    response: isPartialData,
    loading: isPartialFinishedLoader,
  } = useIsOrderProductionPartialFinished();

  const {
    fetch: listStepOptionsFetcher,
    reponseData: listStepOptionsData,
    loading: listStepOptionsLoader,
  } = useListOptions();

  const {
    fetch: getActivityLayoutByOrderFetcher,
    loading: getActivityLayoutByOrderLoader,
    reponseData: getActivityLayoutByOrderData,
  } = useGetActivityLayoutByOrder();

  const allowedToFinishProduction = useMemo(() => {
    const hasMultipleServices = !!userTeam?.configs.find(
      (item) => item.alias === TeamConfigAlias.HAS_MULTIPLE_SERVICES,
    );

    if (!hasMultipleServices) {
      return true;
    }

    const lastServiceItem = userTeam?.configs.find(
      (item) => item.alias === TeamConfigAlias.SERVICE_PRODUCTION_LAST_STEP,
    );

    if (hasMultipleServices && lastServiceItem) {
      const lastServiceList = lastServiceItem.value.split(',');
      return lastServiceList.includes(String(order?.service.id));
    }

    return false;
  }, [userTeam?.id]);

  const validatedStepList = useMemo(() => {
    if (!allowedToFinishProduction) {
      return listStepOptionsData?.filter((item) => item.value !== 'finish');
    }

    return listStepOptionsData;
  }, [listStepOptionsData]);

  const form = useForm({
    initialValues: {
      type: '',
      supervisor: '',
      machine: '',
    },
  });

  const productionMetrics = useMemo(() => {
    let pauseTime = 0;
    let productionTime = 0;
    let setupTime = 0;
    let productionAmount = 0;

    orderProductionList?.forEach((item) => {
      if (item.clockOut !== null) {
        if (item.type === ProductionType.PAUSE) {
          pauseTime += item.timeAmount;
        }
        if (item.type === ProductionType.PRODUCTION) {
          productionTime += item.timeAmount;
          productionAmount += item.amount || 0;
        }
        if (item.type === ProductionType.SETUP) {
          setupTime += item.timeAmount;
        }
      }
    });

    const {
      hours: pauseHours,
      minutes: pauseMinutes,
      seconds: pauseSeconds,
    } = secondsToTime(pauseTime);

    const {
      hours: productionHours,
      minutes: productionMinutes,
      seconds: productionSeconds,
    } = secondsToTime(productionTime);

    const {
      hours: setupHours,
      minutes: setupMinutes,
      seconds: setupSeconds,
    } = secondsToTime(setupTime);

    return {
      pause: {
        hours: pauseHours,
        minutes: pauseMinutes,
        seconds: pauseSeconds,
      },
      production: {
        hours: productionHours,
        minutes: productionMinutes,
        seconds: productionSeconds,
        amount: productionAmount,
      },
      setup: {
        hours: setupHours,
        minutes: setupMinutes,
        seconds: setupSeconds,
      },
    };
  }, [orderProductionList]);

  const { days, hours, minutes, seconds } = useTimer({
    deadline: lastProduction?.clockIn ?? '',
  });

  const formStopProduction = useForm({
    initialValues: {
      amount: '',
    },
  });

  function handleStartProduction() {
    if (form.values.type === '') return;

    if (form.values.type === 'production') {
      setPageModalVisible('begin-production');
    } else {
      handleSubmit(form.values);
    }
  }

  function handleStopProduction() {
    if (form.values.type === '') return;

    if (form.values.type === 'production') {
      setPageModalVisible('end-production');
    } else {
      handleSubmit(form.values);
    }
  }

  async function getOrder() {
    setPageLoading(true);
    const orderResponse = await getOrderRequest(Number(id));
    const orderDetailResponse = await getOrderDetailsRequest(Number(id));
    setPageLoading(false);

    setOrder(orderResponse);
    setOrderDetails(orderDetailResponse);
  }

  async function getOrderProduction() {
    setOrderProductionLoading(true);
    const productionResponse = await getOrderProductionRequest(Number(id));
    const lastProductionResponse = await getLastOrderProductionRequest(
      Number(id),
    );
    setOrderProductionLoading(false);
    setOrderProductionList(productionResponse);
    setLastProduction(lastProductionResponse);
  }

  async function handleSubmit(values: typeof form.values) {
    if (values.type === 'finish') {
      setPageModalVisible('finish-all');
    } else {
      if (!isOnProgress) {
        setLoading(true);
        const response = await setOrderProductionRequest(Number(id), {
          ...values,
          technicalName: user?.username ?? '',
          type: values.type as ProductionType,
        });
        setLoading(false);

        setOrderProductionList([
          ...orderProductionList,
          { ...response, clockOut: null },
        ]);
        setLastProduction({ ...response, clockOut: null });
        setisOnProgress(true);
        setPageModalVisible(null);
      } else {
        await setOutOrderProductionRequest(Number(lastProduction?.id));
        getOrderProduction();
        form.reset();
        setisOnProgress(false);
      }
    }
    await getOrder();
  }

  async function handleSubmitStopProduction(
    values: typeof formStopProduction.values,
  ) {
    setClockOutFetcher({
      id: Number(lastProduction?.id),
      data: {
        amount: Number(values.amount),
      },
      onSuccess: () => {
        setLoading(false);
        getOrderProduction();
        form.reset();
        setisOnProgress(false);
        setPageModalVisible(null);
        formStopProduction.reset();
      },
    });
  }

  async function getTechnicalUsers() {
    try {
      setPageLoading(true);
      const responseUsers = await getFilterUsersListRequest({
        role: UserRole.TECHNICAL,
      });
      setTechnicalUsers(responseUsers);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscas usuários',
        message: 'tente novamente',
      });
    }
  }

  async function getMachineOptions() {
    await listMachineOptionsFetcher({
      query: {
        type: OptionTypes.PRODUCTION,
        subtype: OptionSubTypes.PRODUCTION_MACHINE,
      },
    });
  }

  async function getStepOptions() {
    await listStepOptionsFetcher({
      query: {
        type: OptionTypes.PRODUCTION,
        subtype: OptionSubTypes.STEPS,
      },
    });
  }

  async function finishOrderProduction(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (finishAsPartial) {
      await setPartialFinishFetcher({
        orderId: Number(id),
        onSuccess: () => {
          setPageModalVisible(null);
        },
      });
    } else {
      await setOrderProductionFinishedFetcher({
        orderId: Number(id),
        onSuccess: () => {
          setPageModalVisible(null);
        },
      });
    }

    await getOrder();
    await getOrderProduction();
    await validateIsPartialFinished();
  }

  async function validateIsPartialFinished() {
    await isPartialFinishedFetcher({
      orderId: Number(id),
    });
  }

  async function getLayouts() {
    await getActivityLayoutByOrderFetcher({ orderId: Number(id) });
  }

  const isAllowedToProduction =
    order?.status === OrderStatus.RELEASED_PRODUCTION ||
    order?.status === OrderStatus.ON_PRODUCTION;

  useEffect(() => {
    getOrder();
    getTechnicalUsers();
    getOrderProduction();
    getMachineOptions();
    getStepOptions();
    validateIsPartialFinished();
    getLayouts();
  }, [id]);

  useEffect(() => {
    if (lastProduction && lastProduction.clockOut === null) {
      setisOnProgress(true);
      form.setFieldValue('type', lastProduction.type);
    }
  }, [orderProductionList]);

  if (!order || !orderDetails) return null;

  return (
    <Page>
      <CustomLoader
        loading={
          pageLoading ||
          orderProductionLoading ||
          listMachineOptionsLoader ||
          listStepOptionsLoader ||
          setOrderProductionFinishedLoader ||
          isPartialFinishedLoader ||
          setPartialFinishLoader ||
          getActivityLayoutByOrderLoader
        }
      />
      <Grid gutter="xs" columns={5} w="82vw">
        <Grid.Col span={1}>
          <Flex align="center" justify="start">
            <Button
              onClick={() => rootNavigateGoBack()}
              color="dark.1"
              variant="subtle"
              w={40}
              p={0}
            >
              <IconArrowLeft />
            </Button>
          </Flex>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex align="center" justify="center">
            <Badge color={orderStatusColor[order.status]} mb={6}>
              {orderStatusHumanized[order.status]}
            </Badge>
          </Flex>
        </Grid.Col>
      </Grid>
      <Grid gutter="xs" columns={6} w="82vw">
        <Grid.Col span={3}>
          <Paper p={8} withBorder>
            {order && (
              <OrderDetails
                orderId={order.id}
                sections={['basic', 'prices', 'order-files', 'service-details']}
              />
            )}
          </Paper>
        </Grid.Col>
        <Grid.Col span={3}>
          <Paper p={16} shadow="md">
            <Text fw="bold">Controle de Produção</Text>
            <Divider />
            <Text>
              você deve controlar através destes botões abaixo o andamento da
              produção deste pedido
            </Text>
            <Text>1- selecione a etapa</Text>
            <Text>2- clique no botão "inicar"</Text>
            <Text>
              3- quando terminar a produção selecione a etapa "finalizar" e
              clique em "salvar"
            </Text>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
              {isAllowedToProduction && !isPartialData?.validation && (
                <>
                  <Select
                    w={170}
                    disabled={isOnProgress}
                    required
                    withAsterisk
                    name="role"
                    label="Etapa"
                    placeholder="selecione a etapa"
                    data={
                      validatedStepList?.map((option) => ({
                        label: option.name,
                        value: option.value,
                      })) ?? []
                    }
                    mb={16}
                    mr={8}
                    {...form.getInputProps('type')}
                  />
                  {form.values.type === 'finish' ? (
                    <Button type="submit">Salvar</Button>
                  ) : (
                    <Flex align="center">
                      {lastProduction?.clockOut === null ? (
                        <Title mr={24} color="dark.3">
                          {days > 0 && `${days} ${days > 1 ? 'dias' : 'dia'}`}
                          {` ${hours}:${minutes}:${seconds}`}
                        </Title>
                      ) : (
                        <Title mr={24} color="dark.3">
                          00:00:00
                        </Title>
                      )}
                      <Button
                        onClick={() => handleStartProduction()}
                        disabled={isOnProgress}
                        p={0}
                        w={36}
                        h={36}
                        type="button"
                        mb={-8}
                        radius={18}
                        mr={8}
                      >
                        <IconPlayerPlay color="white" size={26} />
                      </Button>
                      <Button
                        onClick={() => handleStopProduction()}
                        disabled={!isOnProgress}
                        color="red"
                        p={0}
                        w={36}
                        h={36}
                        type="button"
                        mb={-8}
                        radius={18}
                      >
                        <IconPlayerStop color="white" size={26} />
                      </Button>
                    </Flex>
                  )}
                </>
              )}
              <Divider m={8} />
              <ScrollArea mt={8} h={120}>
                {orderProductionList?.reverse()?.map((prodItem, index) => {
                  const { hours, minutes, seconds } = secondsToTime(
                    prodItem.timeAmount,
                  );

                  return (
                    <Flex key={index} mt={8} mb={8}>
                      <ThemeIcon p={4} mr={8} color="green" variant="light">
                        <IconClock size={26} />
                      </ThemeIcon>
                      <Text mr={8}>
                        {formatLocale(prodItem.clockIn, 'DD/MM/YY')}
                      </Text>
                      <Text mr={8}>
                        {orderProductionTypeHumanized[prodItem.type]} -
                      </Text>
                      <Text
                        maw={160}
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                        mr={8}
                      >
                        {prodItem.technical.username}
                      </Text>
                      <Badge>
                        {prodItem.clockOut !== null &&
                          `${hours}h ${minutes}m ${seconds}s`}
                      </Badge>
                      <Badge>{prodItem.amount ?? '--'}</Badge>
                    </Flex>
                  );
                })}
              </ScrollArea>
            </form>
          </Paper>
          <Paper mt={36} p={16} withBorder>
            <Text fw="bold">Métricas de tempo</Text>
            <Divider />
            <Flex mt={8} align="center">
              <Text>em pausa:</Text>
              <Badge>{`${productionMetrics.pause.hours}h ${productionMetrics.pause.minutes}m ${productionMetrics.pause.seconds}s`}</Badge>
            </Flex>
            <Flex mt={8} align="center">
              <Text>em produção:</Text>
              <Badge>{`${productionMetrics.production.hours}h ${productionMetrics.production.minutes}m ${productionMetrics.production.seconds}s`}</Badge>
            </Flex>
            <Flex mt={8} align="center">
              <Text>em setup:</Text>
              <Badge>{`${productionMetrics.setup.hours}h ${productionMetrics.setup.minutes}m ${productionMetrics.setup.seconds}s`}</Badge>
            </Flex>
            <Flex mt={8} align="center">
              <Text>total produzido:</Text>
              <Badge>{productionMetrics.production.amount}</Badge>
            </Flex>
          </Paper>
          {getActivityLayoutByOrderData?.activityLayout && (
            <ActivityLayoutItem
              activityLayout={getActivityLayoutByOrderData.activityLayout}
              files={getActivityLayoutByOrderData.files}
            />
          )}
        </Grid.Col>
      </Grid>
      <Modal
        size={500}
        opened={pageModalVisible === 'begin-production'}
        onClose={() => setPageModalVisible(null)}
        title="Iniciar Produção"
      >
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Grid gutter="xs" columns={2}>
            <Grid.Col mt={8} mb={8} span={1}>
              <Select
                searchable
                required
                withAsterisk
                name="machine"
                label="Máquina"
                placeholder="selecione a máquina"
                data={
                  listMachineOptionsData?.map((option) => ({
                    label: option.name,
                    value: option.value,
                  })) ?? []
                }
                mb={16}
                {...form.getInputProps('machine')}
              />
            </Grid.Col>
            <Grid.Col mt={8} mb={8} span={1}>
              <Select
                searchable
                required
                withAsterisk
                name="supervisor"
                label="Técnico"
                placeholder="selecione e técnico responsavel"
                data={
                  technicalUsers?.map((tItem) => ({
                    label: tItem.name,
                    value: tItem.name,
                  })) ?? []
                }
                mb={16}
                {...form.getInputProps('supervisor')}
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button color="ltpBlue.9" type="submit" loading={loading}>
              Confirmar
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        size={500}
        opened={pageModalVisible === 'end-production'}
        onClose={() => setPageModalVisible(null)}
        title="Finalizar Etapa"
      >
        <form
          onSubmit={formStopProduction.onSubmit((values) =>
            handleSubmitStopProduction(values),
          )}
        >
          <Grid gutter="xs" columns={2}>
            <Grid.Col mt={8} mb={8} span={1}>
              <TextInput
                max={order && order.quantity}
                label="Quantidade Produzida"
                placeholder="digite a quantidade"
                mb={16}
                type="number"
                name="amount"
                {...formStopProduction.getInputProps('amount')}
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button color="ltpBlue.9" type="submit" loading={setClockOutLoader}>
              Confirmar
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        size={500}
        opened={pageModalVisible === 'finish-all'}
        onClose={() => setPageModalVisible(null)}
        title={<Text fw="bold">Finalizar Produção</Text>}
      >
        <form onSubmit={(e) => finishOrderProduction(e)}>
          <Text>
            Ainda existe outra equipe para dar continuidade na produção ?
          </Text>
          <Group mb={16}>
            <Button
              type="button"
              color={finishAsPartial ? 'green' : 'gray'}
              onClick={() => setFinishAsPartial(true)}
            >
              Sim
            </Button>
            <Button
              type="button"
              color={finishAsPartial === false ? 'red' : 'gray'}
              onClick={() => setFinishAsPartial(false)}
            >
              Não
            </Button>
          </Group>
          <Alert color="yellow" icon={<IconInfoCircle />} mb={16}>
            Se você selecionar SIM a produção será passada para outra equipe
            continuar mas se selecionar NÃO a produção será encerrada impedindo
            que outra equipe continue.
          </Alert>
          <Group position="right">
            <Button color="ltpBlue.9" type="submit" loading={setClockOutLoader}>
              Confirmar
            </Button>
          </Group>
        </form>
      </Modal>
    </Page>
  );
}
